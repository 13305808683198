<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
    class="chart bar" />
</template>

<script>
  import { Bar } from "vue-chartjs";
  import { chartLabelFormatter } from "@/helpers/chartHandlers.js";
  import { barChartColours } from "@/helpers/chartColours";
  import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from "chart.js";

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ChartJSPluginDatalabels
  );

  export default {
    name: "BarChart",
    components: { Bar },
    props: {
      chartData: {
        type: Object,
      },
      chartId: {
        type: String,
        default: "bar-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 400,
      },
      height: {
        type: Number,
        default: 400,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Object,
        default: () => {},
      },
      responsive: {
        type: Boolean,
        default: true,
      },
      aspectRatio: {
        type: Number,
        default: 1.6,
      },
      showLegend: {
        type: Boolean,
        default: false,
      },
      legendPosition: {
        type: String,
        default: "bottom",
      },
      showDatalabels: {
        type: Boolean,
        default: true,
      },
      formatMoneyForLabel: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        chartOptions: {
          responsive: this.responsive,
          aspectRatio: this.aspectRatio,
          maintainAspectRatio: true,
          maxBarThickness: 50,
          plugins: {
            legend: {
              display: this.showLegend,
              position: this.legendPosition,
              onClick: this.onClickLegend,
            },
            datalabels: {
              display: this.showDatalabels,
              formatter: (value) => {
                if (this.formatMoneyForLabel) {
                  return this.formatMoney(value);
                } else {
                  return value;
                }
              },
              color: "white",
              backgroundColor: barChartColours(
                this.chartData.datasets[0].data.length
              ),
              font: {
                weight: "bold",
              },
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) =>
                  chartLabelFormatter(
                    tooltipItem,
                    this.formatMoney,
                    this.formatMoneyForLabel
                  ),
              },
            },
          },
        },
        selectedItem: {
          index: null,
          count: 0,
        },
      };
    },
    methods: {
      onClickLegend(_, legendItem, legend) {
        const chart = legend.chart;
        const selectedIndex = legendItem.datasetIndex;
        const items = legend.legendItems;

        this.filterSelectedlegendLabel(chart, items, selectedIndex);
        const total = this.getTotal(chart);
        const isAllOpen = items.every(
          (item, i) => !chart.getDatasetMeta(i).hidden
        );

        this.$emit("updateTotalFigure", total, isAllOpen, selectedIndex);
      },

      filterSelectedlegendLabel(chart, items, selectedIndex) {
        items.forEach((_, itemIndex) => {
          const meta = chart.getDatasetMeta(itemIndex);
          if (
            this.selectedItem.index === selectedIndex &&
            this.selectedItem.count % 2 == 0
          )
            return (meta.hidden = false);
          if (itemIndex !== selectedIndex) return (meta.hidden = true);
          else return (meta.hidden = false);
        });

        if (this.selectedItem.index !== selectedIndex) {
          this.selectedItem = {
            index: selectedIndex,
            count: 0,
          };
        } else this.selectedItem.count++;
        chart.update();
      },

      getTotal(chart) {
        return chart.data.datasets.reduce((acc, curr, i) => {
          const meta = chart.getDatasetMeta(i);
          if (!meta.hidden) {
            curr.data.forEach((value) => (acc += value));
          }
          return acc;
        }, 0);
      },
    },
  };
</script>
