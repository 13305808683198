<template>
  <div class="main-content">
    <h3 class="page-heading">Summary</h3>
    <div class="cards-container" :class="layout">
      <DateRanger
        :startDate="this.dateFrom"
        :endDate="this.dateTo"
        @update="updateDateRange" />

      <HeadlineFigures
        :dateFrom="this.dateFrom"
        :dateTo="this.dateTo"
        :vendors="[]" />

      <div
        class="section-wrapper row align-items-center full-width"
        v-if="this.operatorType === 'venue'">
        <div class="centered-loading-wrapper column" v-if="loadingChartData">
          <LoadingSpinner />
          <p>Loading summary...</p>
        </div>

        <section class="row stretch overview-comparison full-width" v-else>
          <Card :flex="12" title="Vendor Comparison" class="day-comparison">
            <template v-slot:content>
              <div class="table-grid-sections full-width">
                <!-- Per Vendor Table -->
                <section v-if="byVendorTableData != null">
                  <ByVendorTable
                    :dateFrom="this.dateFrom"
                    :dateTo="this.dateTo"
                    :data="byVendorTableData" />
                </section>
                <!-- Per Vendor Chart -->
                <section v-if="byVendorChartData != null">
                  <ul
                    class="daily-aggregation-chart-filters"
                    v-if="byVendorChartData != null">
                    <li
                      class="select-dataset"
                      :class="{ active: selectedDatasetVendors === 'orders' }"
                      @click="selectedDatasetVendors = 'orders'">
                      Orders
                    </li>
                    <li
                      class="select-dataset"
                      :class="{
                        active: selectedDatasetVendors === 'grossRevenue',
                      }"
                      @click="selectedDatasetVendors = 'grossRevenue'">
                      Gross Revenue
                    </li>
                    <li
                      class="select-dataset"
                      :class="{
                        active: selectedDatasetVendors === 'averageTransaction',
                      }"
                      @click="selectedDatasetVendors = 'averageTransaction'">
                      Average Transaction
                    </li>
                    <li
                      class="select-dataset"
                      :class="{
                        active: selectedDatasetVendors === 'cancellations',
                      }"
                      @click="selectedDatasetVendors = 'cancellations'">
                      Cancellations
                    </li>
                    <li
                      class="select-dataset"
                      :class="{ active: selectedDatasetVendors === 'refunds' }"
                      @click="selectedDatasetVendors = 'refunds'">
                      Refunds
                    </li>
                  </ul>
                  <div class="full-width">
                    <div class="full-width" v-if="byVendorChartData != null">
                      <BarChart
                        :chartId="`byVendorBar`"
                        :chart-data="byVendorChartData"
                        :aspectRatio="1.6"
                        :responsive="true"
                        :format-money-for-label="
                          selectedDatasetVendors === 'orders'
                            ? false
                            : selectedDatasetVendors === 'cancellations'
                            ? false
                            : selectedDatasetVendors === 'refunds'
                            ? false
                            : true
                        " />
                    </div>
                    <NoDataWrap v-else>No Data</NoDataWrap>
                  </div>
                </section>
              </div>
            </template>
          </Card>
        </section>
      </div>

      <div class="section-wrapper row align-items-center full-width">
        <div class="centered-loading-wrapper column" v-if="loadingChartData">
          <LoadingSpinner />
          <p>Loading summary...</p>
        </div>

        <section class="row stretch overview-comparison" v-else>
          <!-- Day of Week Table -->
          <Card
            v-if="dailyAggregationTableData != null"
            :flex="12"
            title="Day of the Week Comparison"
            class="day-comparison">
            <template v-slot:content>
              <DailyAggregation
                :dateFrom="this.dateFrom"
                :dateTo="this.dateTo"
                :data="dailyAggregationTableData" />
            </template>
          </Card>

          <!-- Day of Week Chart -->
          <Card :flex="12" title="Daily Aggregation">
            <template v-slot:content>
              <ul
                class="daily-aggregation-chart-filters"
                v-if="dailyAggregationChartData != null">
                <li
                  class="select-dataset"
                  :class="{ active: selectedDataset === 'orders' }"
                  @click="selectedDataset = 'orders'">
                  Orders
                </li>
                <li
                  class="select-dataset"
                  :class="{ active: selectedDataset === 'grossRevenue' }"
                  @click="selectedDataset = 'grossRevenue'">
                  Gross Revenue
                </li>
                <li
                  class="select-dataset"
                  :class="{ active: selectedDataset === 'averageTransaction' }"
                  @click="selectedDataset = 'averageTransaction'">
                  Average Transaction
                </li>
                <li
                  class="select-dataset"
                  :class="{ active: selectedDataset === 'cancellations' }"
                  @click="selectedDataset = 'cancellations'">
                  Cancellations
                </li>
                <li
                  class="select-dataset"
                  :class="{ active: selectedDataset === 'refunds' }"
                  @click="selectedDataset = 'refunds'">
                  Refunds
                </li>
              </ul>
              <div class="full-width">
                <div
                  class="full-width"
                  v-if="dailyAggregationChartData != null">
                  <BarChart
                    :chartId="`byDailyBar`"
                    :chart-data="dailyAggregationChartData"
                    :aspectRatio="1.6"
                    :responsive="true"
                    :format-money-for-label="
                      selectedDataset === 'orders'
                        ? false
                        : selectedDataset === 'cancellations'
                        ? false
                        : selectedDataset === 'refunds'
                        ? false
                        : true
                    " />
                </div>
                <NoDataWrap v-else>No Data</NoDataWrap>
              </div></template
            >
          </Card>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import { screenDetector } from "@/helpers/screenDetector";
  import { isValidDate } from "@/helpers/dateTimes";
  import HeadlineFigures from "@/components/Sales_/HeadlineFigures";
  import DailyAggregation from "@/components/DailyAggregation";
  import ByVendorTable from "@/components/ByVendorTable";
  import Card from "@/components/Card.vue";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import NoDataWrap from "@/components/NoDataWrap.vue";
  import { barChartColours } from "@/helpers/chartColours";
  import BarChart from "@/components/charts/BarChart";
  import DateRanger from "@/components/Inputs/DateRanger";
  import router from "@/router";
  import {
    OPERATORTYPEVENUE,
    OPERATORTYPEVENDOR,
    OPERATORTYPEOUTLET,
  } from "@/constants/operatorTypes.ts";

  export default {
    components: {
      HeadlineFigures,
      DailyAggregation,
      ByVendorTable,
      Card,
      LoadingSpinner,
      NoDataWrap,
      BarChart,
      DateRanger,
    },

    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        dateFrom: this.$route.params.dateFrom,
        dateTo: this.$route.params.dateTo,
        showChart: true,
        layout: "desktop",
        loading: false,
        loadingChartData: true,
        dailyAggregationTableData: null,
        dailyAggregationChartData: null,
        byVendorTableData: null,
        byVendorChartData: null,
        rangeRangeData: null,
        vendorsData: null,
        selectedDatasetVendors: "orders",
        selectedDataset: "orders",
        vendors: [],
        days: [
          "Mondays",
          "Tuesdays",
          "Wednesdays",
          "Thursdays",
          "Fridays",
          "Saturdays",
          "Sundays",
        ],
      };
    },

    watch: {
      selectedDataset() {
        this.setUpDailyAggregationChartData(this.rangeData);
      },

      selectedDatasetVendors() {
        this.setUpByVendorChartData(this.vendorsRangeData);
      },
    },

    methods: {
      updateDateRange(data) {
        router.push({
          name: "/sales/summary/dates",
          params: {
            operatorType: this.operatorType,
            operatorId: this.operatorId,
            dateFrom: data.startDate,
            dateTo: data.endDate,
          },
        });
      },

      updateIfDesktopLayoutState() {
        this.layout = screenDetector();
      },

      getByVendorData() {
        this.loadingChartData = true;

        this.$store.state.apiPrivate.client.endpoints.venueSummarySalesDaily
          .getbyVendor(
            { dateFrom: this.dateFrom, dateTo: this.dateTo },
            this.operatorId
          )
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
            return Promise.reject("Could not download transaction summary.");
          })
          .then((data) => {
            if (data.length < 1) {
              this.byVendorTableData = []; // use [] for grid to 'No Rows To Show'
              this.byVendorChartData = null;
              this.loadingChartData = false;
              return;
            }

            this.vendorsRangeData = data;

            this.vendors = [];

            for (const vendor of this.vendorsRangeData) {
              this.vendors.push(vendor.name);
            }

            this.setUpByVendorChartData(this.vendorsRangeData);
            this.byVendorTableData = this.vendorsRangeData;
            this.loadingChartData = false;
          })
          .catch((err) => {
            this.loadingChartData = false;
            window.log.error(err);
          });
      },

      getDailyAggregationtData() {
        this.loadingChartData = true;
        this.getDailyAggregationEndpoint()
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
            return Promise.reject("Could not download transaction summary.");
          })
          .then((data) => {
            if (data.length < 1) {
              this.rangeData = null;
              this.dailyAggregationChartData = null;
              this.dailyAggregationTableData = null;
              this.loadingChartData = false;
              return;
            }

            this.rangeData = data;
            this.setUpDailyAggregationChartData(this.rangeData);
            this.dailyAggregationTableData = data;
            this.loadingChartData = false;
          })
          .catch((err) => {
            this.loadingChartData = false;
            window.log.error(err);
          });
      },

      getDailyAggregationEndpoint() {
        switch (this.operatorType) {
          case OPERATORTYPEOUTLET:
            return this.$store.state.apiPrivate.client.endpoints.outletSummarySales.get(
              { dateFrom: this.dateFrom, dateTo: this.dateTo },
              this.operatorId
            );
          case OPERATORTYPEVENDOR:
            return this.$store.state.apiPrivate.client.endpoints.vendorSummarySales.get(
              { dateFrom: this.dateFrom, dateTo: this.dateTo },
              this.operatorId
            );
          case OPERATORTYPEVENUE:
            return this.$store.state.apiPrivate.client.endpoints.venueSummarySalesDaily.get(
              { dateFrom: this.dateFrom, dateTo: this.dateTo },
              this.operatorId
            );
          default:
            window.log.error(
              "[❌] Failed to resolve a summary endpoint to use for your operation type."
            );
        }
      },

      setUpByVendorChartData(data) {
        let subset = [];

        for (const vendor of data) {
          subset.push(vendor[this.selectedDatasetVendors]);
        }

        this.byVendorChartData = {
          datasets: [
            {
              data: subset,
              backgroundColor: barChartColours(subset.length),
            },
          ],
          labels: this.vendors,
        };
      },

      setUpDailyAggregationChartData(data) {
        let subset = [];

        for (let i = 0; i < this.days.length; i++) {
          let dayData = null;
          if (this.operatorType == "venue") {
            dayData = data.find((x) => x.name === this.days[i]);
          } else {
            dayData = data.groupings.find((x) => x.name === this.days[i]);
          }

          if (dayData) {
            subset.push(dayData[this.selectedDataset]);
          } else {
            subset.push(0);
          }
        }

        this.dailyAggregationChartData = {
          datasets: [
            {
              data: subset,
              backgroundColor: barChartColours(subset.length),
            },
          ],
          labels: this.days,
        };
      },
    },

    beforeMount() {
      if (!isValidDate(this.dateFrom) || !isValidDate(this.dateTo)) {
        router.push({
          name: "/summary",
          params: {
            operatorType: this.operatorType,
            operatorId: this.operatorId,
          },
        });

        return;
      }

      this.updateIfDesktopLayoutState();
      window.addEventListener("resize", this.updateIfDesktopLayoutState);

      this.getDailyAggregationtData();

      if (this.operatorType == "venue") {
        this.getByVendorData();
      }
    },

    unmounted() {
      window.removeEventListener("resize", this.updateIfDesktopLayoutState);
    },
  };
</script>

<style lang="scss">
  .revenue-payout-summary {
    display: flex;
    flex-direction: row;
    &__data {
      width: 50%;
      max-width: 800px;
      min-width: 300px;
      .xl-desktop &,
      .desktop & {
        margin-left: 4rem;
      }
    }
    &.tablet,
    &.mobile {
      flex-direction: column;
    }
    &__title,
    &__sub-title {
      color: #aaa;
      margin: 0;
    }
    &__value {
      align-items: end;
      height: 50px;
      p {
        font-size: 2rem;
        margin: 0;
      }
      span.percentage {
        margin-left: 1rem;
        line-height: 2rem;
      }
    }
    &__sub-title {
      font-size: 0.7rem !important;
      margin-bottom: 0.1rem !important;
    }
    &__sub-value {
      align-items: center;
      p {
        font-size: 1.6rem;
        margin: 0;
      }
    }
    &__revenue-content {
      align-items: flex-end;
      & div:first-child {
        .xl-desktop &,
        .desktop {
          margin-right: 3rem;
        }
      }
    }
  }

  span.growth-percentage-chip {
    background: $col_beta;
    border-radius: $card_radius;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0 1rem;
    &.declined {
      background: $col_alpha;
    }
  }

  .select-dataset {
    cursor: pointer;
    &.active {
      background: $col_alpha-lightest;
    }
  }

  .dailyaggregation {
    @media screen and (max-width: 1024px) {
      width: 100% !important;
    }
  }

  .daily-aggregation-chart-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    li {
      display: block;
      padding: 0.35rem 0.65rem;
      border-radius: 0.5rem;
      font-weight: 600;
      &:not(.active) {
        color: $col_faded-darker;
      }
    }
  }

  .overview-comparison {
    .table-grid-sections {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
      section:first-child {
        border-right: $table_border;
        padding-right: 2rem;
      }
      @media screen and (max-width: $vertical_tablet_width) {
        grid-template-columns: repeat(1, 1fr);
        .ag-theme-alpine {
          max-height: 360px;
        }
      }
    }
  }

  .day-comparison {
    .card__content {
      align-items: flex-start;
    }
  }
</style>
