<template>
  <Card :flex="3" :title="title" :tabletFull="false" :tabletHalf="true">
    <template #upper-right>
      <span class="headline-figures-upper-right">
        <span v-if="selectedFigure">
          {{ moneyFormat ? formatMoney(selectedFigure) : selectedFigure }} /
        </span>
        {{ moneyFormat ? formatMoney(chartDataFigure) : chartDataFigure }}
        <span v-if="percentage">({{ percentage }}%)</span>
      </span>
    </template>
    <template #content>
      <div class="full-width">
        <div class="full-width" v-if="chartData.datasets?.length > 0">
          <BarChart
            :showDatalabels="operatorType === OPERATORTYPEVENDOR"
            :showLegend="operatorType === OPERATORTYPEVENUE"
            :chartData="chartData"
            :format-money-for-label="moneyFormat"
            :aspectRatio="1.1"
            :maintainAspectRatio="true"
            @update-total-figure="updateTotalFigure" />
        </div>
        <NoDataWrap v-else>No Data</NoDataWrap>
      </div>
    </template>
  </Card>
</template>

<script>
  import Card from "@/components/Card.vue";
  import NoDataWrap from "@/components/NoDataWrap.vue";
  import BarChart from "@/components/charts/BarChart";

  // constants
  import {
    OPERATORTYPEVENDOR,
    OPERATORTYPEVENUE,
  } from "@/constants/operatorTypes.ts";

  export default {
    components: { Card, NoDataWrap, BarChart },
    props: {
      title: {
        type: String,
      },
      chartDataFigure: {
        type: Number,
      },
      moneyFormat: {
        type: Boolean,
        default: false,
      },
      chartData: {
        type: Object,
      },
      operatorType: {
        type: String,
      },
      isAverage: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        OPERATORTYPEVENDOR,
        OPERATORTYPEVENUE,
        selectedFigure: null,
      };
    },
    methods: {
      updateTotalFigure(figure, isAllOpen, selectedIndex) {
        const totalFigure = this.isAverage
          ? this.getAverage(this.chartData.datasets[selectedIndex].data, figure)
          : figure;

        if (isAllOpen) return (this.selectedFigure = null);
        this.selectedFigure = totalFigure;
      },
      getAverage(data, figure) {
        const filteredData = data.filter((item) => item !== 0);
        return figure / filteredData.length;
      },
    },
    computed: {
      percentage() {
        if (!this.selectedFigure) return null;
        return ((this.selectedFigure / this.chartDataFigure) * 100).toFixed(2);
      },
    },
  };
</script>

<style lang="scss">
  .card__title {
    flex-wrap: wrap !important;
  }
  .headline-figures-upper-right {
    width: 100%;
    margin-top: 10px;
  }
  .section-wrapper {
    min-height: 180px;
    width: 100%;
    .centered-loading-wrapper {
      box-shadow: $card_shadow;
      border-radius: $card_radius;
      min-height: 290px;
    }

    section.headline-figures {
      width: 100%;
      margin: 0;
    }
  }
  .chart.bar {
    width: 100% !important;
    min-width: 180px !important;
    canvas {
      width: 100% !important;
    }
  }

  .headline-figures-upper-right {
    color: #2b8e7a;
    font-weight: 700;
    font-size: 20px;
  }
</style>
