<template>
  <div class="grid-view">
    <AgGridVue
      style="width: 100%; height: 360px"
      class="ag-theme-alpine"
      :class="{ 'no-data': !rowData }"
      @gridReady="onGridReady"
      :columnDefs="columnDefs"
      :rowData="rowData" />
    <p class="error grid-error-overlay" v-if="errorTextFromAPI">
      {{ errorTextFromAPI }}
    </p>
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue3";
  import { LocalDate, DateTimeFormatter } from "@js-joda/core";

  export default {
    components: {
      AgGridVue,
    },
    props: {
      dateFrom: {
        type: String,
        default: LocalDate.now()
          .minusDays(LocalDate.now().dayOfWeek().ordinal() + 7)
          .format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      },
      dateTo: {
        type: String,
        default: LocalDate.now()
          .minusDays(LocalDate.now().dayOfWeek().ordinal())
          .format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      },
      data: {
        type: Object,
      },
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        errorTextFromAPI: null,
        gridApi: null,
        gridColumnApi: null,
        rowData: [],
        vendors: [],
      };
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: "Vendor",
            field: "name",
            resizable: true,
            sortable: true,
            filter: true,
            width: 150,
            pinned: "left",
          },
          {
            headerName: "Orders",
            field: "orders",
            resizable: true,
            sortable: true,
            filter: true,
            width: 120,
          },
          {
            headerName: "Gross Revenue",
            field: "grossRevenue",
            resizable: true,
            sortable: true,
            filter: true,
            width: 150,
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Avg Transaction",
            field: "averageTransaction",
            resizable: true,
            sortable: true,
            filter: true,
            width: 150,
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Cancellations",
            field: "cancellations",
            resizable: true,
            sortable: true,
            filter: true,
            width: 150,
          },
          {
            headerName: "Refunds",
            field: "refunds",
            resizable: true,
            sortable: true,
            filter: true,
            width: 120,
          },
        ];
      },
    },
    watch: {
      data() {
        this.setUpData();
      },
    },
    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setUpData();
      },

      setUpData() {
        this.vendors = [];

        for (const vendor of this.data) {
          this.vendors.push(vendor.name);
        }

        this.gridApi?.showLoadingOverlay();
        this.rowData = [];
        for (let i = 0; i < this.vendors.length; i++) {
          let vendorData = null;

          vendorData = this.data.find((x) => x.name === this.vendors[i]);

          if (vendorData) {
            this.rowData.push(vendorData);
          } else {
            this.rowData.push({
              averageTransaction: "0.00",
              cancellations: "0",
              grossRevenue: "0.0",
              name: this.vendors[i],
              orders: "0",
              refunds: "0",
            });
          }
        }
        this.gridApi?.hideOverlay();
      },
    },
  };
</script>
