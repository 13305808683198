<template>
  <div class="grid-view">
    <AgGridVue
      style="width: 100%; height: 500px"
      class="ag-theme-alpine"
      :class="{ 'no-data': !rowData }"
      @gridReady="onGridReady"
      :defaultColDef="defaultColDef"
      :columnDefs="columnDefs"
      :rowData="rowData">
    </AgGridVue>
    <p class="error grid-error-overlay" v-if="errorTextFromAPI">
      {{ errorTextFromAPI }}
    </p>
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue3";
  import { LocalDate, DateTimeFormatter } from "@js-joda/core";

  export default {
    components: {
      AgGridVue,
    },
    props: {
      dateFrom: {
        type: String,
        default: LocalDate.now()
          .minusDays(LocalDate.now().dayOfWeek().ordinal() + 7)
          .format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      },
      dateTo: {
        type: String,
        default: LocalDate.now()
          .minusDays(LocalDate.now().dayOfWeek().ordinal())
          .format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      },
      data: {
        type: Object,
      },
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        errorTextFromAPI: null,
        gridApi: null,
        gridColumnApi: null,
        rowData: [],
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: true,
          minWidth: 100,
          width: 130
        },
        days: [
          "Mondays",
          "Tuesdays",
          "Wednesdays",
          "Thursdays",
          "Fridays",
          "Saturdays",
          "Sundays",
        ],
      };
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: "Day",
            field: "name",
            pinned: "left",
          },
          {
            headerName: "Orders",
            field: "orders",
            width: 100,
          },
          {
            headerName: "Gross Revenue",
            field: "grossRevenue",
            width: 150,
            valueFormatter: ({ value }) => {
              return this.formatMoney(value);
            },
          },
          {
            headerName: "Avg Transaction",
            field: "averageTransaction",
            width: 150,
            valueFormatter: ({ value }) => {
              return this.formatMoney(value);
            },
          },
          {
            headerName: "Cancellations",
            field: "cancellations",
          },
          {
            headerName: "Refunds",
            field: "refunds",
          },
        ];
      },
    },
    watch: {
      data() {
        this.setUpData();
      },
    },
    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setUpData();
      },

      setUpData() {
        this.gridApi?.showLoadingOverlay();
        this.rowData = [];
        for (let i = 0; i < this.days.length; i++) {
          let dayData = null;
          if (this.operatorType == "venue") {
            dayData = this.data.find((x) => x.name === this.days[i]);
          } else
            dayData = this.data.groupings.find((x) => x.name === this.days[i]);

          if (dayData) {
            this.rowData.push(dayData);
          } else {
            this.rowData.push({
              averageTransaction: "0.00",
              cancellations: "0",
              grossRevenue: "0.0",
              name: this.days[i],
              orders: "0",
              refunds: "0",
            });
          }
        }
        this.gridApi?.hideOverlay();
      },
    },
  };
</script>
