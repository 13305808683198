import store from "@/store";

export function getHeadlinesEndpoint(
  operatorType,
  operatorId,
  dateFrom,
  dateTo
) {
  switch (operatorType) {
    case "vendor":
      return store.state.apiPrivate.client.endpoints.vendorSummarySales.getHeadlines(
        {
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
        operatorId
      );
    case "outlet":
      return store.state.apiPrivate.client.endpoints.outletSummarySales.getHeadlines(
        {
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
        operatorId
      );
    case "venue":
      return store.state.apiPrivate.client.endpoints.venueSummarySales.getHeadlines(
        {
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
        operatorId
      );
    default:
      window.log.error(
        "[❌] Failed to resolve a refund endpoint to use for your operation type."
      );
  }
}
