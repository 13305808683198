<template>
  <div class="section-wrapper row align-items-center">
    <div class="centered-loading-wrapper column" v-if="loadingChartData">
      <LoadingSpinner />
      <p>Loading summary...</p>
    </div>
    <section class="headline-figures row stretch full-width" v-else>
      <SummaryBarChart
        title="Gross Revenue"
        :chartData="grossRevenueChartData"
        :chartDataFigure="grossRevenueChartDataFigure"
        :moneyFormat="true"
        :operatorType="operatorType" />
      <SummaryBarChart
        title="Total Orders"
        :chartData="totalOrdersChartData"
        :chartDataFigure="totalOrdersChartData.dataSetsValueTotal"
        :operatorType="operatorType" />
      <SummaryBarChart
        title="Avg. Transaction Value"
        :chartData="avgTransactionValueChartData"
        :chartDataFigure="avgTransactionAll"
        :moneyFormat="true"
        :isAverage="true"
        :operatorType="operatorType" />
      <div class="column full-height tablet-row refund-cancellation-section">
        <Card title="Refunds" :autoFlexBasis="true">
          <template v-slot:content>
            <ValueCard
              :value="refundsData?.value"
              :subValue="refundsData?.total"
              subTitle="Number of Refunds" />
          </template>
        </Card>
        <Card title="Cancellations" :autoFlexBasis="true">
          <template v-slot:content>
            <ValueCard
              :value="cancellationsData?.value"
              :subValue="cancellationsData?.total"
              subTitle="Number of Cancellations" />
          </template>
        </Card>
      </div>
    </section>
  </div>
</template>

<script>
  import { LocalDate, DateTimeFormatter } from "@js-joda/core";
  import Enumerable from "linq";

  // helpers
  import { barChartColours } from "@/helpers/chartColours";
  import { getHeadlinesEndpoint } from "@/helpers/headlines";
  import { upperCamelCaseToString } from "@/helpers/stringFormatter.js";

  // components
  import ValueCard from "@/components/CardViews/ValueCard";
  import SummaryBarChart from "./SummaryBarChart.vue";
  import Card from "@/components/Card.vue";

  export default {
    components: { Card, ValueCard, SummaryBarChart },
    props: {
      dateFrom: {
        type: String,
        default: LocalDate.now()
          .minusDays(LocalDate.now().dayOfWeek().ordinal() + 7)
          .format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      },
      dateTo: {
        type: String,
        default: LocalDate.now()
          .minusDays(LocalDate.now().dayOfWeek().ordinal())
          .format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      },
      vendors: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        errorTextFromAPI: null,
        chartData: null,
        loadingChartData: true,
        grossRevenueChartData: null,
        totalOrdersChartData: null,
        avgTransactionValueChartData: null,
        avgTransactionAll: null,
        refundsData: null,
        cancellationsData: null,
      };
    },
    computed: {
      vendorIds() {
        return this.vendors?.map((x) => x.id);
      },
    },
    watch: {
      operatorType() {
        this.getHeadlines();
      },
      operatorId() {
        this.getHeadlines();
      },
      dateFrom() {
        this.getHeadlines();
      },
      dateTo() {
        this.getHeadlines();
      },
      vendors() {
        this.getHeadlines();
      },
    },
    beforeMount() {
      this.getHeadlines();
    },
    methods: {
      getHeadlines() {
        this.loadingChartData = true;
        this.errorTextFromAPI = null;

        getHeadlinesEndpoint(
          this.operatorType,
          this.operatorId,
          this.dateFrom,
          this.dateTo
        )
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
          })
          .then((data) => {
            // determine and cache the setup function so we can call it for each chart below
            let setUpChartFunction = this.getChartSetUpFunction();

            this.grossRevenueChartData = setUpChartFunction(
              data,
              "grossRevenue"
            );
            this.grossRevenueChartDataFigure =
              this.grossRevenueChartData.dataSetsValueTotal;

            this.totalOrdersChartData = setUpChartFunction(data, "totalOrders");
            this.avgTransactionValueChartData = setUpChartFunction(
              data,
              "averageTransaction"
            );

            this.avgTransactionAll = this.getAvgTransactions(data);

            this.refundsData = this.getRefundsData(data);
            this.cancellationsData = this.getCancellationsData(data);

            this.loadingChartData = false;
          })
          .catch((err) => {
            window.log.error(err);
            this.errorTextFromAPI = err;
          });
      },

      getRefundsData(data) {
        if (this.operatorType === "vendor" || this.operatorType === "outlet") {
          return {
            total: data["totalRefunds"],
            value: this.formatMoney(data["totalRefundValue"]),
          };
        }

        const total = data.reduce(
          (acc, data) => acc + data.headlines.totalRefunds,
          0
        );

        const value = data.reduce(
          (acc, data) => acc + data.headlines.totalRefundValue,
          0
        );
        return {
          total: total,
          value: this.formatMoney(value),
        };
      },

      getCancellationsData(data) {
        if (this.operatorType === "vendor" || this.operatorType === "outlet") {
          return {
            total: data["totalCancellations"],
            value: this.formatMoney(data["totalCancellationValue"]),
          };
        }

        const total = data.reduce(
          (acc, data) => acc + data.headlines.totalCancellations,
          0
        );

        const value = data.reduce(
          (acc, data) => acc + data.headlines.totalCancellationValue,
          0
        );
        return {
          total: total,
          value: this.formatMoney(value),
        };
      },

      getAvgTransactions(data) {
        if (this.operatorType === "vendor" || this.operatorType === "outlet") {
          return data["averageTransaction"];
        } else {
          return (
            data.reduce(
              (sum, curr) => (sum += curr.headlines.averageTransaction),
              0
            ) / data.length
          );
        }
      },

      // Determine data formatting funciton (vendor / venue)
      getChartSetUpFunction() {
        switch (this.operatorType) {
          case "vendor":
            return this.setUpChartsData;
          case "venue":
            return this.setUpVenueChartsData;
          case "outlet":
            return this.setUpChartsData;

          default:
            window.log.error(
              "[❌] Failed to resolve a chart setup function to use for your operation type."
            );
        }
      },

      // Process the returned data for ChartJS (if Vendor)
      setUpChartsData(data, title) {
        const filteredData = Object.keys(data)
          // Only includes and not IS the same.
          .filter((string) => string.includes(title) && string != title)
          .map((key) => ({
            name: key,
            value: data[key],
          }));

        if (filteredData.length < 1) {
          return;
        }

        return {
          labels: filteredData.map((o) =>
            upperCamelCaseToString(o.name.split(title)[1])
          ),
          datasets: [
            {
              data: filteredData.map((o) => o.value),
              backgroundColor: barChartColours(filteredData.length),
            },
          ],
          dataSetsValueTotal: Enumerable.from(filteredData).sum((x) => x.value),
        };
      },

      // Process the returned data for ChartJS (if Venue)
      setUpVenueChartsData(data, title) {
        let labels = [];
        let datasets = [];
        let valueTotal = 0;
        const colours = barChartColours(data.length);

        for (let i = 0; i < data.length; i++) {
          const filteredData = Object.keys(data[i].headlines)
            .filter((string) => string.includes(title) && string != title)
            .map((key) => ({
              name: key,
              value: data[i].headlines[key],
            }));

          if (filteredData.length < 1) {
            return;
          }

          labels = filteredData.map((o) =>
            upperCamelCaseToString(o.name.split(title)[1])
          );

          datasets.push({
            label: data[i].ref.name,
            data: filteredData.map((o) => o.value),
            backgroundColor: colours[i],
          });

          valueTotal += filteredData.reduce((acc, data) => acc + data.value, 0);
        }

        return {
          labels: labels,
          datasets: datasets,
          dataSetsValueTotal: valueTotal,
        };
      },
    },
  };
</script>
